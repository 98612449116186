import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Helmet from 'react-helmet';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Padding, Margin } from 'styled-components-spacing';

import {
  HomeNursingGraphicIcon,
  StethoscopeGraphicIcon,
  ToothShieldGraphicIcon,
  PharmaceuticalGraphicIcon,
  WellnessScoreGraphicIcon,
  PriceGraphicIcon,
} from '@nib/icons';

import { Section, Stack, Box, Tiles } from '@nib/layout';

import Accordion from '@nib-components/accordion';
import { PrimaryButton } from '@nib-components/button';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Container from '@nib-components/container';
import { Tabs, TabList, TabPanel } from '@nib-components/tabs';
import Link from '@nib-components/link';
import { colorWhite, colorTrueGreen } from '@nib-components/theme';

import BenefitsTable, { LinkWrapper } from '../components/BenefitsTable';
import CallBackModal from '../components/CallBackModal';
import HeroPanel from '../components/HeroPanel';
import IconPoint from '../components/IconPoint';
import Layout from '../components/Layout';
import { HeroPanelCopy, Tab } from '../components/styledComponents';
import UtilityButtons from '../components/UtilityButtons';
import VerticalFeaturePanel from '../components/VerticalFeaturePanel';
import { urlConstants } from '../constructs/constants';
import { ContentfulDocument, UtilityLinkModel } from '../constructs/models';
import metrics from '../metrics';

import desktop from '../img/adviser-plans/hero/desktop.jpg';
import desktop2x from '../img/adviser-plans/hero/desktop@2x.jpg';
import mobile from '../img/adviser-plans/hero/mobile.jpg';
import mobile2x from '../img/adviser-plans/hero/mobile@2x.jpg';
import tablet from '../img/adviser-plans/hero/tablet.jpg';
import tablet2x from '../img/adviser-plans/hero/tablet@2x.jpg';
import AdviserBenefitStepImg from '../img/adviser-plans/AdviserBenefitStep.jpg';

import ultimateHealthBenefits from '../content/ultimate-health-benefits';
import easyHealthBenefits from '../content/easy-health-benefits';

// custom icons
import PercentageDownIcon from '../img/life-and-living/icons/PercentageDownIcon.svg';
import PuzzleIcon from '../img/life-and-living/icons/PuzzleIcon.svg';
import ClipboardTickIcon from '../img/life-and-living/icons/ClipboardTickIcon.svg';
import ReinforcedShieldWithSurroundingArrowsIcon from '../img/life-and-living/icons/ReinforcedShieldWithSurroundingArrowsIcon.svg';

export const AdviserPlansPageQuery = graphql`
  query AdviserPlansPageQuery {
    easyHealth: allContentfulDocument(
      filter: { name: { eq: "Easy Health Policy Document" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    easyHealthBrochure: allContentfulDocument(
      filter: { name: { eq: "Easy Health Brochure" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    easyHealthBrochureSimplifiedChinese: allContentfulDocument(
      filter: { name: { eq: "Easy Health Brochure - Chinese" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    ultimateHealthMaxPolicy: allContentfulDocument(
      filter: { name: { eq: "Ultimate Health Max Policy" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    ultimateHealthPolicy: allContentfulDocument(
      filter: { name: { eq: "Ultimate Health Policy" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    ultimateHealthBrochure: allContentfulDocument(
      filter: { name: { eq: "Ultimate Health & Ultimate Health Max Brochure" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    ultimateHealthBrochureSimplifiedChinese: allContentfulDocument(
      filter: { name: { eq: "Ultimate Health & Ultimate Health Max Brochure - Chinese" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    nonPharmacPlusTandC: allContentfulDocument(
      filter: { name: { eq: "Non PharmacPlus Terms And Conditions" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
  }
`;

const GettingCoverSection = () => (
  <Section role="section" background="sageGreen60">
    <Stack space={4}>
      <Box textAlign="center">
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
          Get cover through an adviser
        </Heading>
      </Box>
      <Copy measure={false}>
        You can buy your health insurance through an independent licensed financial advice provider.
        Their financial advisers or nominated representatives have specialist knowledge to help you
        identify and prioritise your needs and choose cover that suits your circumstances and
        budget.
      </Copy>
      <Copy measure={false}>
        Our comprehensive life and living insurance products are offered through advisers. You can
        enjoy all the benefits of being an nib member, plus have additional support from your
        adviser with claims or any future changes to your life and living cover.
      </Copy>
    </Stack>
  </Section>
);

const adviserBenefitStepFeatures = [
  {
    heading: 'Specialist advice',
    content: () =>
      "An adviser can help identify what's essential for you, explain the different options available and find the right health insurance plan to suit your needs.",
    image: AdviserBenefitStepImg,
  },
  {
    heading: 'Ongoing support',
    content: () =>
      'Your needs can change over time. A good adviser will check in regularly to make sure your plan still meets your needs.',
    image: AdviserBenefitStepImg,
  },
  {
    heading: 'There to help when you need treatment',
    content: () =>
      'If you have a serious health condition requiring treatment, your adviser will work with you and nib to get your claim assessed to help get you back on your feet.',
    image: AdviserBenefitStepImg,
  },
  {
    heading: 'Financial guidance',
    content: () =>
      'An adviser can give you advice about health insurance and many can also advise other financial services as well, such as life insurance, mortgages, investing and KiwiSaver.',
    image: AdviserBenefitStepImg,
  },
];

const adviserBenefitStepDescription = {
  title: 'The benefits of using an adviser',
  subTitle: null,
};

const BenefitSection = (): JSX.Element => (
  <Section role="section">
    <VerticalFeaturePanel
      description={adviserBenefitStepDescription}
      features={adviserBenefitStepFeatures}
      useAnimation={false}
    />
  </Section>
);

const UltimateHealthPlanDescription = (): JSX.Element => (
  <Padding bottom={6}>
    <Stack space={4}>
      <Copy measure={false}>
        Our most comprehensive private health insurance plans are Ultimate Health Max and Ultimate
        Health. They provide the very best cover and protection that we offer for a wide range of
        health treatments and the costs that go with them<sup>1</sup>. Ultimate Health Max includes
        substantial levels of cover for non-PHARMAC funded medicines approved by Medsafe and
        guaranteed wording
        <sup>2</sup>.
      </Copy>
      <Copy measure={false}>
        Your adviser will help you complete a health questionnaire. That way, you know exactly what
        you’re covered for and have peace of mind when claiming. Advisers can also explain the
        differences between the two plans.
      </Copy>
      <Copy measure={false}>
        You can choose the following Options to add to your plan<sup>1</sup>:
      </Copy>
    </Stack>
  </Padding>
);

const UltimateHealthOptions = (): JSX.Element => (
  <Margin bottom={6}>
    <Tiles columns={{ xs: 1, md: 2 }} space={{ xs: 4, md: 6 }}>
      <IconPoint
        largeText={false}
        icon={HomeNursingGraphicIcon}
        title="Specialist Option"
        description="Cover for specialist consultations and diagnostic procedures that don’t result in hospitalisation"
      />
      <IconPoint
        largeText={false}
        icon={StethoscopeGraphicIcon}
        title="GP Option"
        description="Cover for GP visits, GP minor surgery, prescriptions and more"
      />
      <IconPoint
        largeText={false}
        icon={ToothShieldGraphicIcon}
        title="Dental, Optical and Therapeutic Option"
        description="Cover for dental and optical care, treatment by Chiropractors and more"
      />
      <IconPoint
        largeText={false}
        icon={PharmaceuticalGraphicIcon}
        title={<Link href={urlConstants.nonPharmac}>Non-PHARMAC Plus Option</Link>}
        description={
          <div>
            Increase the maximum amount of cover for the cost of Medsafe-approved,{' '}
            <Link href={urlConstants.nonPharmac}>non-PHARMAC funded medicines</Link> (additional to
            the standard maximum under Ultimate Health Max). Not available under Ultimate Health.
          </div>
        }
      />
      <IconPoint
        largeText={false}
        icon={WellnessScoreGraphicIcon}
        title="Proactive Health Option"
        description="Cover for preventative screening, vaccinations, allergy tests and more"
      />
      <IconPoint
        largeText={false}
        icon={PriceGraphicIcon}
        title="Serious Condition Financial Support Option"
        description="A one-off lump sum to help reduce the strain of dealing with specific trauma conditions"
      />
    </Tiles>
  </Margin>
);

const UltimateHealthSection = ({
  ultimateHealthPolicyMax,
  ultimateHealthPolicy,
  ultimateHealthBrochure,
  ultimateHealthBrochureSimplifiedChinese,
}) => (
  <Section>
    <Container>
      <UltimateHealthPlanDescription />
      <UltimateHealthOptions />
      <BenefitsTable
        rows={ultimateHealthBenefits(
          ultimateHealthPolicyMax,
          ultimateHealthPolicy,
          ultimateHealthBrochure,
          ultimateHealthBrochureSimplifiedChinese
        )}
        numberOfColumns={2}
      />
      <Margin top={{ xs: 4, md: 6 }}>
        <LinkWrapper>
          <Link href="#things-to-know">*Important things to know</Link>
        </LinkWrapper>
      </Margin>
    </Container>
  </Section>
);

const EasyHealthPlanDescription = (): JSX.Element => (
  <Padding bottom={6}>
    <Stack space={4}>
      <Copy measure={false}>
        Our Easy Health plan covers treatments and procedures in a private hospital<sup>3</sup>. You
        don’t need to complete a health questionnaire when you join. This means some pre-existing
        conditions are covered after a period of three years<sup>4</sup>, which can be important if
        you’ve had health issues in the past and would like to have them covered in the future.
      </Copy>
      <Copy measure={false}>
        You can also choose the following options to add to your Easy Health plan<sup>1</sup>:
      </Copy>
    </Stack>
  </Padding>
);

const EasyHealthOptions = (): JSX.Element => (
  <Margin bottom={6}>
    <Tiles columns={{ xs: 1, md: 2 }} space={{ xs: 4, md: 6 }}>
      <IconPoint
        largeText={false}
        icon={PharmaceuticalGraphicIcon}
        title="Non-PHARMAC Plus Option"
        description="Increase the maximum amount of cover for the cost of Medsafe-approved, non-PHARMAC funded drugs"
      />
      <IconPoint
        largeText={false}
        icon={WellnessScoreGraphicIcon}
        title="Proactive Health Option"
        description="Cover for preventative screening, vaccinations, allergy tests and more"
      />
      <IconPoint
        largeText={false}
        icon={PriceGraphicIcon}
        title="Serious Condition Lump Sum Option"
        description="A one-off lump sum to help reduce the strain of dealing with specific trauma conditions"
      />
    </Tiles>
  </Margin>
);

const EasyHealthSection = ({
  easyHealth,
  easyHealthBrochure,
  easyHealthBrochureSimplifiedChinese,
}) => (
  <Section>
    <EasyHealthPlanDescription />
    <EasyHealthOptions />
    <BenefitsTable
      rows={easyHealthBenefits(easyHealth, easyHealthBrochure, easyHealthBrochureSimplifiedChinese)}
      numberOfColumns={1}
    />
    <Margin top={{ xs: 4, md: 6 }}>
      <LinkWrapper>
        <Link href="#things-to-know">*Important things to know</Link>
      </LinkWrapper>
    </Margin>
  </Section>
);

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

const LargeCopy = styled(Copy)`
  font-size: 32px;
`;

const DiscountContainer = styled.div`
  background-color: #01573c;
  border-radius: 20px;
  padding: 32px;
  gap: 8px;
`;

const DiscountSection = (): JSX.Element => {
  return (
    <Section background={'trueGreen'}>
      <Box display="flex" flexDirection="column" gap={6} color="white">
        <Heading component="h2" size={{ xs: 2 }} fontType="serif">
          Save with nib's discounts
        </Heading>
        <DiscountContainer>
          <Box display="flex" width="100%" flexDirection="column" gap={4}>
            <Box display="flex" gap={3} alignItems="center">
              <Box
                background="sageGreen"
                display="flex"
                minWidth="60px"
                minHeight="60px"
                width="60px"
                height="60px"
                borderRadius="full"
                padding={2}
                justifyContent="center"
              >
                <Icon src={PercentageDownIcon} />
              </Box>

              <Heading component="h3" size={{ xs: 2 }} fontType="serif">
                Health and Life & Living Bundled Discount
              </Heading>
            </Box>
            <Box>
              <LargeCopy transform="uppercase" size="large">
                Get <b>10%</b> off your health insurance premiums
              </LargeCopy>
              <Copy measure={false}>
                when you bundle any new eligible Health Insurance and Ultimate Life & Living
                insurance*.
              </Copy>
            </Box>
            <Stack space={6}>
              <Box display="flex" gap={3} alignItems="center">
                <Box width="72px" minWidth="72px">
                  <Icon src={PuzzleIcon} />
                </Box>
                <Box>
                  <Heading component="h4" size={3}>
                    Comprehensive Cover
                  </Heading>
                  <Copy measure={false}>
                    Get peace of mind knowing you have protection for both your health and financial
                    wellbeing.
                  </Copy>
                </Box>
              </Box>
              <Box display="flex" gap={3} alignItems="center">
                <Box width="72px" minWidth="72px">
                  <Icon src={ClipboardTickIcon} />
                </Box>
                <Box>
                  <Heading component="h4" size={3}>
                    Easy and cost-effective:
                  </Heading>
                  <Copy measure={false}>
                    Apply for Health and Life & Living cover together and save money.
                  </Copy>
                </Box>
              </Box>
              <Box display="flex" gap={3} alignItems="center">
                <Box width="72px" minWidth="72px">
                  <Icon src={ReinforcedShieldWithSurroundingArrowsIcon} />
                </Box>
                <Box>
                  <Heading component="h4" size={3}>
                    Convenience:{' '}
                  </Heading>
                  <Copy measure={false}>
                    Save time and effort by having your covers with a single insurer.{' '}
                  </Copy>
                </Box>
              </Box>
            </Stack>
          </Box>
        </DiscountContainer>
        <Copy align="center" measure={false}>
          *Terms and conditions apply. Discounts do not apply to the policy fee component of the
          premiums.
        </Copy>
      </Box>
    </Section>
  );
};

const TabLabel = styled.div`
  ${breakpoint('lg')`
    width: 200px;
    white-space: normal;
    overflow-wrap: normal;
  `};
`;

const StyledTabList = styled(TabList)`
  li:not(.react-tabs__tab--selected) {
    color: ${colorWhite};
  }
`;

const PlanSection = ({
  easyHealth,
  easyHealthBrochure,
  easyHealthBrochureSimplifiedChinese,
  ultimateHealthMaxPolicy,
  ultimateHealthPolicy,
  ultimateHealthBrochure,
  ultimateHealthBrochureSimplifiedChinese,
}): JSX.Element => (
  <>
    <Section role="section">
      <Stack space={4}>
        <Box textAlign="center" background="sneezy">
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
            Our plans through an adviser
          </Heading>
        </Box>
        <Copy measure={false}>
          Your adviser will be able to help find the right cover for you, your partner and family.
          With the wide range of cover options available, every person can get the cover they need.
        </Copy>
      </Stack>
    </Section>
    <Tabs defaultIndex={0}>
      <StyledTabList center={true} variation="container" background="warmWhite60">
        <Tab>
          <TabLabel>Ultimate Health Max and Ultimate Health</TabLabel>
        </Tab>
        <Tab>
          <TabLabel>Easy Health</TabLabel>
        </Tab>
      </StyledTabList>
      <TabPanel>
        <UltimateHealthSection
          ultimateHealthPolicyMax={ultimateHealthMaxPolicy}
          ultimateHealthPolicy={ultimateHealthPolicy}
          ultimateHealthBrochure={ultimateHealthBrochure}
          ultimateHealthBrochureSimplifiedChinese={ultimateHealthBrochureSimplifiedChinese}
        />
      </TabPanel>
      <TabPanel>
        <EasyHealthSection
          easyHealth={easyHealth}
          easyHealthBrochure={easyHealthBrochure}
          easyHealthBrochureSimplifiedChinese={easyHealthBrochureSimplifiedChinese}
        />
      </TabPanel>
    </Tabs>
  </>
);

const ContactSection = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Section role="section" background="trueGreen">
      <CallBackModal variant="Adviser" isOpen={isOpen} setOpen={setIsOpen} />
      <Stack space={4}>
        <Box textAlign="center">
          <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h2" color="white">
            Talk to an adviser
          </Heading>
        </Box>
        <Copy align="center" color="white" measure={false}>
          An adviser can talk with you about the financial risks of ill health and the various
          insurance options available to you.
        </Copy>
        <Copy align="center" color="white" measure={false}>
          Call your adviser today or if you don’t have one, simply fill in the contact form and an
          independent financial advice provider will get in touch.
        </Copy>
        <Box textAlign="center">
          <PrimaryButton onClick={() => setIsOpen(true)}>Talk to an adviser</PrimaryButton>
        </Box>
      </Stack>
    </Section>
  );
};

const ListCopy = styled(Copy)`
  > ul {
    padding-left: 1.25rem;
  }
  > ul > li {
    margin-bottom: 0.5rem;
  }
  > ul > li > ul {
    padding-left: 1.25rem;
  }
  > ul > li > ul > li:first-child {
    margin-top: 0.5rem;
  }
  > ul > li > ul > li {
    margin-bottom: 0.5rem;
  }
`;

const StyledHeading = styled(Heading)`
  font-size: 20px;
`;

const StyledAccordion = styled(Accordion)`
  h4 {
    color: ${colorTrueGreen};
  }
`;

interface ThingsToKnowProps {
  easyHealth: ContentfulDocument;
  ultimateHealthMaxPolicy: ContentfulDocument;
  ultimateHealthPolicy: ContentfulDocument;
  nonPharmacPlusTandC: ContentfulDocument;
}
const ThingsToKnowSection = ({
  easyHealth,
  ultimateHealthMaxPolicy,
  ultimateHealthPolicy,
  nonPharmacPlusTandC,
}: ThingsToKnowProps): JSX.Element => (
  <Box role="section" marginBottom={7} marginTop={4}>
    <Container>
      <Stack space={{ xs: 4, md: 6 }}>
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
          Things to know before you join
        </Heading>
        <Box>
          <Margin bottom={2}>
            <StyledHeading size={4} color="trueGreen">
              <sup>1</sup> For Ultimate Health and Ultimate Health Max plans:
            </StyledHeading>
          </Margin>
          <ListCopy measure={false} component="div">
            <ul>
              <li>
                For base cover and Options, costs are covered up to the benefit limit, in line with
                the policy terms. Base Cover will also be subject to any excesses payable.
              </li>
              <li>
                You need to tell us your medical history when you apply to join. Your adviser will
                help you to do this through a health questionnaire and means you have certainty
                about what you are and are not covered for.
              </li>
              <li>
                The <Link href={urlConstants.findAProvider}>First Choice network</Link> only applies
                to Ultimate Health and applies to all benefits, including Options.
              </li>
              <li>
                The following waiting periods apply:
                <ul>
                  <li>Oral surgery for extraction of unerupted or impacted teeth – 12 months</li>
                  <li>Dental, Optical, and Therapeutic Option – Six months</li>
                  <li>Proactive Health Option – Six months</li>
                  <li>Serious Condition Financial Support Option – 90 days</li>
                  <li>GP Option – 90 days</li>
                </ul>
              </li>
            </ul>
          </ListCopy>
        </Box>
        <Box>
          <Margin bottom={2}>
            <StyledHeading size={4} color="trueGreen">
              <sup>2</sup> What is guaranteed wording?
            </StyledHeading>
          </Margin>
          <Copy measure={false}>
            The benefits and terms for the Ultimate Health Max plan are largely protected from
            negative changes during the life of the policy. While we can change how much you pay for
            your health insurance each year, we may only alter the benefits or terms if:
          </Copy>
          <ListCopy measure={false} component="div">
            <ul>
              <li>The law that applies to the policy changes (e.g. taxation); or</li>
              <li>The policyowner and/or insured person didn’t disclose information to us; or</li>
              <li>
                New benefits or increases to existing benefits are added to the policy. These
                changes will only apply to relevant treatments from the date the change takes place.
              </li>
            </ul>
          </ListCopy>
        </Box>
        <Box>
          <Margin bottom={2}>
            <StyledHeading size={4} color="trueGreen">
              <sup>3</sup> For Easy Health
            </StyledHeading>
          </Margin>
          <ListCopy measure={false} component="div">
            <ul>
              <li>
                For the Base Cover and Options, costs are covered up to the benefit limit, in line
                with the policy terms. The Base Cover is also subject to any excess payable.
              </li>
              <li>
                You don’t need to tell us your medical history when you apply to join, but we will
                check your eligibility for claims when you make a claim
              </li>
              <li>
                The <Link href={urlConstants.findAProvider}>First Choice network</Link> applies to
                all benefits, including Options.
              </li>
              <li>
                Waiting periods apply to:
                <ul>
                  <li>Oral surgery for extraction of unerupted or impacted teeth – 12 months</li>
                  <li>Proactive Health Option – Six months</li>
                  <li>Serious Condition Lump Sum Option – 90 days</li>
                </ul>
              </li>
            </ul>
          </ListCopy>
        </Box>
        <Box>
          <Margin bottom={2}>
            <StyledHeading size={4} color="trueGreen">
              <sup>4</sup> Am I covered for any pre-existing conditions?
            </StyledHeading>
          </Margin>
          <Copy measure={false}>
            In summary, a pre-existing condition is any injury, illness, symptom or diagnosis that
            happened any time prior to your policy starting.
          </Copy>

          <ListCopy measure={false} component="div">
            <ul>
              <li>
                Many pre-existing conditions will be covered after three years, as explained in the{' '}
                <Link href={easyHealth?.file?.url} target="_blank">
                  Easy Health Policy
                </Link>{' '}
                document.
              </li>
              <li>
                We won’t cover any pre-existing condition at any time under the Serious Condition
                Lump Sum Option
              </li>
              <li>
                We will never cover any of the following if they are pre-existing at the time of
                application:
                <ul>
                  <li>Cancer</li>
                  <li>Cardiovascular conditions</li>
                  <li>Hip, knee or back issues</li>
                  <li>Transplant surgery</li>
                  <li>Reconstructive or reparative surgery</li>
                </ul>
              </li>
            </ul>
          </ListCopy>
        </Box>
        <StyledAccordion borderTop={false} borderBottom>
          <Accordion.Item title="Check the detail">
            <Copy measure={false}>
              Only the main features and benefits of our Ultimate Health Max, Ultimate Health and
              Easy Health plans are outlined here. For a full explanation of all benefits, benefit
              limits and exclusions, view the Policy document for each plan:
            </Copy>
            <ListCopy measure={false} component="div">
              <ul>
                <li>
                  <Link href={ultimateHealthMaxPolicy?.file?.url} target="_blank">
                    Ultimate Health Max
                  </Link>
                </li>
                <li>
                  <Link href={ultimateHealthPolicy?.file?.url} target="_blank">
                    Ultimate Health
                  </Link>
                </li>
                <li>
                  <Link href={easyHealth?.file?.url} target="_blank">
                    Easy Health
                  </Link>
                </li>
              </ul>
            </ListCopy>
          </Accordion.Item>
          <Accordion.Item title="You're not locked in">
            <Copy measure={false}>
              You get a 14-day free look period when you join, to help you decide whether your plan
              is a good fit. We’ll refund any premiums you’ve paid if you decide the plan isn’t for
              you in that first 14 days (and if you haven’t made any claims). If you ever feel your
              health cover is no longer what you need, you can talk through your options with your
              adviser.
            </Copy>
          </Accordion.Item>
        </StyledAccordion>
      </Stack>
    </Container>
  </Box>
);

const NavigationSection = (): JSX.Element => {
  const utilityLinks: UtilityLinkModel[] = [
    {
      subtitle: 'Need an adviser?',
      title: 'Find one here',
      url: '#adviser-contact',
    },
    {
      subtitle: 'NZ health insurance?',
      title: 'Learn how it works',
      url: '/health-insurance',
    },
    {
      subtitle: 'Want to know more?',
      title: 'Learn about nib',
      url: '/about-nib',
    },
  ];
  return (
    <Section role="section" background="warmWhite60" padding={4}>
      <UtilityButtons buttons={utilityLinks} flex={true} />
    </Section>
  );
};

interface GraphQLAdviserPlansDocumentNode {
  edges: ContentfulAdviserPlansDocumentEdgeShape[];
}
const title = 'Adviser Plan Comparison';
const description =
  'Get health insurance through an adviser and find the right cover to suit your personal needs.';

interface ContentfulAdviserPlansDocumentEdgeShape {
  node: {
    document: ContentfulDocument;
  };
}

interface AdviserPlansPageProps {
  data: {
    easyHealth: GraphQLAdviserPlansDocumentNode;
    easyHealthBrochure: GraphQLAdviserPlansDocumentNode;
    easyHealthBrochureSimplifiedChinese: GraphQLAdviserPlansDocumentNode;
    ultimateHealthMaxPolicy: GraphQLAdviserPlansDocumentNode;
    ultimateHealthPolicy: GraphQLAdviserPlansDocumentNode;
    ultimateHealthBrochure: GraphQLAdviserPlansDocumentNode;
    ultimateHealthBrochureSimplifiedChinese: GraphQLAdviserPlansDocumentNode;
    nonPharmacPlusTandC: GraphQLAdviserPlansDocumentNode;
  };
}

const AdviserPlansPage = (props: AdviserPlansPageProps): JSX.Element => {
  const easyHealth = props?.data?.easyHealth?.edges?.[0]?.node?.document;
  const easyHealthBrochure = props?.data?.easyHealthBrochure?.edges?.[0]?.node?.document;
  const easyHealthBrochureSimplifiedChinese =
    props?.data?.easyHealthBrochureSimplifiedChinese?.edges?.[0]?.node?.document;
  const ultimateHealthMaxPolicy = props?.data?.ultimateHealthMaxPolicy?.edges?.[0]?.node?.document;
  const ultimateHealthPolicy = props?.data?.ultimateHealthPolicy?.edges?.[0]?.node?.document;
  const ultimateHealthBrochure = props?.data?.ultimateHealthBrochure?.edges?.[0]?.node?.document;
  const ultimateHealthBrochureSimplifiedChinese =
    props?.data?.ultimateHealthBrochureSimplifiedChinese?.edges?.[0]?.node?.document;
  const nonPharmacPlusTandC = props?.data?.nonPharmacPlusTandC?.edges?.[0]?.node?.document;

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'WebPage',
    identifier: 'https://www.nib.co.nz/adviser-plans',
    url: 'https://www.nib.co.nz/adviser-plans',
    name: 'Talk to an adviser about nib health insurance | nib',
    description:
      'Get health insurance through an adviser and find the right cover to suit your personal needs.',
    isPartOf: 'https://www.nib.co.nz',
  };
  const JSONschema = JSON.stringify(schema);

  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <HeroPanel
        images={{
          desktop: desktop,
          desktop2x: desktop2x,
          mobile: mobile,
          mobile2x: mobile2x,
          tablet: tablet,
          tablet2x: tablet2x,
        }}
        title="Health insurance through an adviser"
        variation="condensed"
      >
        <HeroPanelCopy>
          An adviser works with you to find a health insurance plan to suit your needs.
        </HeroPanelCopy>
        <PrimaryButton href="#adviser-contact">Talk to an adviser</PrimaryButton>
      </HeroPanel>
      <GettingCoverSection />
      <BenefitSection />
      <a id="adviser-contact" />
      <ContactSection />
      <PlanSection
        easyHealth={easyHealth}
        easyHealthBrochure={easyHealthBrochure}
        easyHealthBrochureSimplifiedChinese={easyHealthBrochureSimplifiedChinese}
        ultimateHealthMaxPolicy={ultimateHealthMaxPolicy}
        ultimateHealthPolicy={ultimateHealthPolicy}
        ultimateHealthBrochure={ultimateHealthBrochure}
        ultimateHealthBrochureSimplifiedChinese={ultimateHealthBrochureSimplifiedChinese}
      />
      <a id="adviser-discount" />
      <DiscountSection />
      <a id="things-to-know" />
      <ThingsToKnowSection
        easyHealth={easyHealth}
        ultimateHealthMaxPolicy={ultimateHealthMaxPolicy}
        ultimateHealthPolicy={ultimateHealthPolicy}
        nonPharmacPlusTandC={nonPharmacPlusTandC}
      />
      <NavigationSection />
    </Layout>
  );
};

export default metrics({ pageName: 'adviser-plans' })(AdviserPlansPage);
